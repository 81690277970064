<template>
  <div>
    <index v-if="active === 0"/>
    <mycustomform v-if="active === 1"/>
  </div>
</template>

<script>
import index from './index.vue'
import form from './form.vue'
export default {
  watch: {
    'active'(newValue, oldValue) {
      if (newValue === 0) {
        index.methods.getAllData()
        // console.log('index', index)
      }
    },
  },
  components: {
    index,
    mycustomform: form,
  },
  data() {
    return {
      active: 0,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
