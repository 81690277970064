<template>
  <span v-html="displayValue"></span>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      displayValue: '',
    }
  },
  beforeMount() {
    this.displayValue = this.params.value
  },
})
</script>

<style>

</style>
