<template>
  <div class="custom-tooltip" v-if="visible">
    <strong>
      {{ pesan }}
    </strong>
  </div>
  <div v-else></div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      visible: false,
      pesan: '',
      data: null,
      color: null,
    }
  },
  beforeMount() {
    this.data = this.params.api.getDisplayedRowAtIndex(
      this.params.rowIndex,
    ).data
    if (this.data.error !== undefined) {
      // console.log('this.params', )
      var target = this.data.error.find(x => x.field === this.params.colDef.field)
      // console.log('target', target)
      if (target !== undefined) {
        this.color = this.params.color || 'white'
        this.visible = true
        this.pesan = target.error
      } else {
        this.visible = false
        this.pesan = ''
      }
    // console.log('this.visible', this.visible)
    }
  },
})
</script>

<style>
.custom-tooltip {
  position: absolute;
  background-color: black;
  border: 1px solid;
  color: white;
  padding: 5px;
  border-radius: 6px;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}
</style>
