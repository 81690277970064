<template>
  <span>
    <a href="javascript:void(0)" @click="buttonClicked()" class="text-danger"><a-icon type="delete"></a-icon></a>
  </span>
</template>

<script>
import * as lou from '@/services/data/lou'
import { Modal } from 'ant-design-vue'
import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      displayValue: '',
    }
  },
  // props: ['params'],
  beforeMount() {
    this.displayValue = this.params.value
  },
  methods: {
    buttonClicked() {
      var indexTarget = this.$parent.rowData.findIndex(
        (x) => x.id === this.params.data.id,
      )
      // this.$parent.rowData.splice(indexTarget, 1)
      // console.log('indexTarget', this.$parent.rowData[indexTarget])
      if (this.$parent.rowData[indexTarget].uuid === undefined) {
        this.showDeleteConfirm(this.$parent.rowData[indexTarget])
      } else {
        lou.shownotif('Informasi', 'Data total tidak dapat dihapus.', 'info')
      }
      // console.log('this.params', this.params.data)
      // console.log('this.$parent', this.$parent)
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2(
            'transaksi/purchase_payment/' + deldata.id,
          )
          if (response) {
            this.$parent.$parent.$parent.editdata = response.data
            this.$parent.$parent.$parent.resetForm()
            this.$parent.$parent.$parent.getEditData()
            // this.getAllData()
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
  },
})
</script>

<style>

</style>
